import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './screens/App';
import './App.css';

import $ from 'jquery';
import dataTable from 'datatables';


window.$ = $;

// import './assets/fonts/mv-icons/mv-icons.min.css';
// import './assets/fonts/dmsans/dmsans.css';

ReactDOM.render((
  <BrowserRouter>
    <App />
  </BrowserRouter>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
