import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Card, Row, Col, CardBody } from 'reactstrap';
import { withRouter, Switch, Route } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

import Passengers from '../screens/Passengers';
import Vehicle from '../screens/Vehicle';
import Trips from '../screens/Trips';
import Ratings from '../screens/Ratings';

class App extends Component {
  componentDidMount = () => {

  }

  getTitle = () => {
    console.log(this.props.location.pathname);
    const path = this.props.location && this.props.location.pathname && this.props.location.pathname.split("/") && this.props.location.pathname.split("/")[1];
    return path || '';
    console.log(path)
  }

  render() {
    return (
      <div className="sidebar-mini">
        <div className="wrapper">
          <Header />
          <Sidebar />
          <div className="content-wrapper">

            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1 style={{textTransform: 'capitalize'}}>{ this.getTitle() }</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                      {/* <li className="breadcrumb-item active">DataTables</li> */}
                    </ol>
                  </div>
                </div>
              </div>
            </section>

            <section className="content-header">
              <div className="container-fluid">
                <Switch>
                  <Route exact path="/" component={Trips} />
                  <Route path="/trips" component={Trips} />
                  <Route path="/vehicle" component={Vehicle} />
                  <Route path="/passengers" component={Passengers} />
                  <Route path="/ratings" component={Ratings} />
                </Switch>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(observer(App));
