import React, { Component } from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';

export default class Vehicle extends Component {
  render = () => {
    return (
      <Row>
        <Col>
          <Card>
            <CardBody>
              Vehicle
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
};