import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LogoSRC from '../../src/assets/images/logo-onemv.svg';


export default () => {

  const [index, setIndex] = useState(0);

  const menu = [
    {
      name: 'Trips',
      icon: 'sitemap',
      link: '/trips',
    },
    {
      name: 'Ratings',
      icon: 'star-half-alt',
      link: '/ratings',
    },
    // {
    //   name: 'Passengers',
    //   icon: 'users',
    //   link: '/passengers',
    // },
    // {
    //   name: 'Vehicle',
    //   icon: 'bus',
    //   link: '/vehicle',
    // },
  ];

  return (
    <aside style={{height: '100vh'}} className="main-sidebar sidebar-blue-primary elevation-4">
      
      <div className="user-panel  pt-2 pb-1 d-flex">
        {/* <div style={{ fontSize: '2em' }} className="p-1 mt-2">
          <i className="fas fa-user-circle" />
        </div>
        <div className="info" style={{ top: '.7em', position: 'relative' }}>
          <span className="d-block">Alexander Pierce</span>
          <span style={{ fontSize: '12px', top: '-8px', position: 'relative' }}>useremail@gmail.com</span>
        </div> */}
        <img
            style={{height: "100%", width: "100%"}}
            src={LogoSRC}
            alt="AdminLTE Logo"
          />
      </div>
      <div className="user-panel m-auto d-flex">
        
      </div>

      <hr />

      <div className="sidebar">
        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

            {
              menu.map((item, i) => (
                <li key={`sidebar-menu-${i + 1}`} className="nav-item has-treeview">
                  <Link onClick={() => setIndex(i)} to={item.link} className={`nav-link ${index === i ? 'active' : ''}`}>
                    <i className={`nav-icon fas fa-${item.icon}`} />
                    <p>
                      { item.name }
                    </p>
                  </Link>
                </li>
              ))
            }
          </ul>
        </nav>
      </div>
    </aside>
  )
};