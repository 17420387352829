import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { passengersApiBaseUrl, divisionsApiBaseUrl } from '../utils/Endpoints';
import { buildRequestHeaders } from '../utils';

class QueryComponent extends Component {
  state = {
    loading: true,
    data: null,
    error: null,
  }

  componentDidMount = () => {
    const {
      url,
      input,
      method,
      endpoint,
      isPolling,
    } = this.props;
    const baseUrl = endpoint === 'passenger' ? passengersApiBaseUrl : divisionsApiBaseUrl;
    fetch(baseUrl + url, {
      method,
      ...(input ? { body: JSON.stringify(input) } : {}),
      mode: 'cors',
      headers: buildRequestHeaders(),
    }).then(res => res.json()).then((data) => {
      this.setState({
        loading: false,
        data,
      }, () => {
        // do long polling if enabled in props
        if (isPolling) this.longPolling();
      });
    }).catch((error) => {
      this.setState({
        loading: false,
        error,
      });
    });
  }

  componentWillUnmount = () => {
    if (this.timeout) clearTimeout(this.timeout);
  }

  // does not trigger loading, so new data should be injected to the child
  longPolling = () => {
    const {
      url,
      input,
      method,
      endpoint,
      isPolling,
      pollingInterval,
    } = this.props;
    const baseUrl = endpoint === 'passenger' ? passengersApiBaseUrl : divisionsApiBaseUrl;
    if (isPolling) {
      this.timeout = setTimeout(() => {
        fetch(baseUrl + url, {
          method,
          ...(input ? { body: JSON.stringify(input) } : {}),
          mode: 'cors',
          headers: buildRequestHeaders(),
        }).then(res => res.json()).then((data) => {
          this.setState({
            loading: false,
            data,
          }, () => this.longPolling());
        }).catch((error) => {
          this.setState({
            loading: false,
            error,
          }, () => this.longPolling());
        });
      }, pollingInterval);
    }
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { children } = this.props;
    const { loading, data, error } = this.state;

    return (
      <>
        {children({ loading, data, error })}
      </>
    );
  }
}
QueryComponent.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.string,
  endpoint: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  input: PropTypes.object,
  isPolling: PropTypes.bool,
  pollingInterval: PropTypes.number,
};

QueryComponent.defaultProps = {
  method: 'GET',
  input: null,
  isPolling: false,
  endpoint: 'passenger',
  pollingInterval: 3000, // (3 Seconds) for sample purposes only
};

export default QueryComponent;
