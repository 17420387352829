import React, { Component } from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import DataTable from '../../components/DataTable';
import { Spinner } from '../../components';
import { fetchPassengerApi, lookUpData } from '../../utils';

export default class Ratings extends Component {
  state = {
    trips: [],
    nextTrips: [],
    loading: true,
    limit: 25,
    page: 1,
    search: '',
    filteredData: [],
    tableError: false,
  }

  componentDidMount = async () => {
    const { page, limit } = this.state;
    try {
      this.fetchData(limit, page);
    } catch (error) {
      this.setState({ tableError: true });
      console.log(error);
    }
  }


  fetchData = async (limit, page, callback) => {
    this.setState({ loading: true }, async () => {
      try {
        const result = await fetchPassengerApi('get', `/ratings?limit=${limit}&page=${page}`);
        this.setState({ trips: result.data, loading: false }, callback);
      } catch (error) {
        this.setState({ tableError: true });
        console.log(error);
      }
    });
  }

  canNextPage = () => {
    const { nextTrips, trips, limit } = this.state;

    // if last items is less than the limit
    if (trips % limit === 0) return true;

    if (nextTrips.length > 0) return true;

    return false;
  }

  canPrevPage = () => !(this.state.page >= 2);

  nextPage = () => {
    const { page, limit } = this.state;
    // this.setState({ page: this.state.page + 1 });
    this.fetchData(limit, page + 1, () => this.setState({ page: page + 1 }));
    console.log(page)
  }

  previousPage = () => {
    const { page, limit } = this.state;
    // this.setState({ page: this.state.page + 1 });
    this.fetchData(limit, page - 1, () => this.setState({ page: page - 1 }));
  }

  goToPage = (page) => {

  }

  onSearch = (value) => {
    const { trips } = this.state;
    this.setState({ search: value, filteredData: lookUpData(trips, ['tripId', 'ratings[0].passengerId', 'ratings[0].rating', 'ratings[0].comments'], value) });
  }

  render = () => {
    const { trips, loading, page, limit, search, filteredData, tableError } = this.state;
    return (
      <Row>
        <Col>
          <Card>
            <CardBody>
              <DataTable
                loading={loading}
                columns={[
                  {
                    Header: 'Trip ID',
                    accessor: 'tripId',
                  },
                  {
                    Header: 'Passenger ID',
                    accessor: 'ratings[0].passengerId',
                  },
                  {
                    Header: 'Trip Rating',
                    accessor: 'ratings[0].rating',
                  },
                  {
                    Header: 'Comment',
                    accessor: 'ratings[0].comments',
                  }
                ]}
                data={search.length > 0 ? filteredData : trips}
                page={page}
                limit={limit}
                nextPage={this.nextPage}
                previousPage={this.previousPage}
                onSearch={this.onSearch}
                canPrevPage={this.canPrevPage()}
                canNextPage={this.canNextPage()}
                error={tableError}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
};