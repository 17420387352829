import React, { Component } from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';


export default class Passengers extends Component {
  render = () => {
    return (
      <Row>
        <Col>
          <Card>
            <CardBody>
              Passengers
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
};