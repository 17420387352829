/* eslint-disable import/prefer-default-export, import/no-named-default */
export const queryParamsToObject = (url) => {
  // eslint-disable-next-line prefer-const
  let result = {};
  const searchIndex = url.indexOf('?');

  if (searchIndex === -1) return result;
  const sPageURL = url.substring(searchIndex + 1);
  const sURLVariables = sPageURL.split('&');
  for (let i = 0; i < sURLVariables.length; i += 1) {
    const sParameterName = sURLVariables[i].split('=');
    // eslint-disable-next-line prefer-destructuring
    result[sParameterName[0]] = sParameterName[1];
  }
  return result;
};

export const buildRequestHeaders = () => ({
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'x-division-id': localStorage.getItem('x-division-id'),
  Authorization: `Bearer ${localStorage.getItem('userToken') || ''}`,
});

export const isObjectEmpty = obj => Object.keys(obj).length === 0 && obj.constructor === Object;

export const formatTime = (date) => {
  let hours = date.getUTCHours() - 6;
  let minutes = date.getUTCMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = (hours % 12) ? hours % 12 : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const strTime = `${hours}:${minutes} ${ampm}`;
  return strTime;
};


const getMonthName = (index) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];
  return months[index];
};

export const getFormattedDate = (date) => {
  const dateObj = new Date(date);
  const month = getMonthName(dateObj.getMonth());
  const year = dateObj.getFullYear();
  const day = dateObj.getDate();

  return `${month} ${day}, ${year}`;
};

export const formatDateFromNumber = (numDate) => {
  const strDate = numDate.toString();
  const year = strDate.substring(0, 4);
  const monthNum = parseInt(strDate.substring(4, 6), 10) - 1;
  const day = strDate.substring(6, 8);

  return `${getMonthName(monthNum)} ${day}, ${year}`;
};

const deg2rad = deg => deg * (Math.PI / 180);

// Haversine formula
export const getDistanceInKM = (locA, locB) => {
  const R = 6371;
  const dLat = deg2rad(locB.lat - locA.lat);
  const dLng = deg2rad(locB.lng - locB.lng);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
    + Math.cos(deg2rad(locA.lat)) * Math.cos(deg2rad(locB.lat))
    * Math.sin(dLng / 2) * Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in km;
};

const calcTime = (distance, rate = 40) => distance / rate;

export const getTimeStr = (distance, rate = 40) => {
  const time = calcTime(distance, rate);
  const mins = Math.ceil(time * 60);

  return mins > 1 ? `${mins} mins away` : 'Your trip has arrived';
};

export const generateID = () => {
  const length = 20; // default length
  const characterSet = 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz0123456789';
  let result = '';
  for (let i = 0; i < length; i += 1) {
    result += characterSet.charAt(Math.floor(Math.random() * length));
  }
  return result;
};

export const fetchPassengerApi = (method, url, input) => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_PASSENGER_API_PROTOCOL}://${process.env.REACT_APP_PASSENGER_API_ENDPOINT}${url}`, {
      method,
      ...(input ? { body: JSON.stringify(input) } : {}),
      mode: 'cors',
      headers: {
        'x-division-id': '5d27639d3b52ef56e8260ac7',
        key: process.env.REACT_APP_PASSENGER_API_KEY,
        secret: process.env.REACT_APP_PASSENGER_API_SECRET,
      },
    }).then(res => res.json()).then((data) => {
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
};

export const lookUpData = (data = [{}], fields = [], searchValue) => {
  const isIncluded = (row, rowFields, rowSearchValue) => {
    const result = rowFields.filter((field) => {
      
      let item = field.includes('.') ? field.split('.').reduce((o, i) => o[i], row) : row[field]

      if (item === undefined || item === null) return false;
      item = item.toString().toLowerCase();
      return item.includes(rowSearchValue);
    });
    return result.length > 0;
  };

  return data.filter(item => isIncluded(item, fields, searchValue));
};
