import React from 'react';
import { Link } from 'react-router-dom';
import LogoSRC from '../../src/assets/images/logo-onemv.svg';

export default () => (
  <nav className="main-header navbar navbar-expand navbar-blue navbar-dark">
    {/* <!-- Left navbar links --> */}
    <ul className="navbar-nav">
      <li className="nav-item">
        <Link to="/" className="nav-link" data-widget="pushmenu"><i className="fas fa-bars" /></Link>
      </li>
      <li>
        <a className="nav-link text-right" data-toggle="dropdown" href="#">
          <i className="fas fa-user-circle mr-2" />
          Alexander Pierce
        </a>
      </li>
      {/* <li className="nav-item d-none d-sm-inline-block">
        <Link to="/" className="nav-link">Home</Link>
      </li> */}
    </ul>

    {/* <!-- Right navbar links --> */}
    <ul className="navbar-nav ml-auto">
      {/* <!-- Messages Dropdown Menu --> */}
      <li className="nav-item dropdown">
        {/* <a href="../../index3.html" className="brand-link">
          <img
            src={LogoSRC}
            alt="AdminLTE Logo"
          />
        </a> */}
        {/* <a className="nav-link">
          <img
            className=" w-100 h-100"
            src={LogoSRC}
            alt="AdminLTE Logo"
          />
        </a> */}
        {/* <a className="nav-link" data-toggle="dropdown" href="#">
          <i className="far fa-comments"></i>
          <span className="badge badge-danger navbar-badge">3</span>
        </a>
        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <a href="#" className="dropdown-item">
            <div className="media">
              <img src="../../dist/img/user1-128x128.jpg" alt="User Avatar" className="img-size-50 mr-3 img-circle" />
              <div className="media-body">
                <h3 className="dropdown-item-title">
                  Brad Diesel
                  <span className="float-right text-sm text-danger"><i className="fas fa-star"></i></span>
                </h3>
                <p className="text-sm">Call me whenever you can...</p>
                <p className="text-sm text-muted"><i className="far fa-clock mr-1"></i> 4 Hours Ago</p>
              </div>
            </div>
          </a>
          <div className="dropdown-divider"></div>
          <a href="#" className="dropdown-item">
            <div className="media">
              <img src="../../dist/img/user8-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" />
              <div className="media-body">
                <h3 className="dropdown-item-title">
                  John Pierce
                  <span className="float-right text-sm text-muted"><i className="fas fa-star"></i></span>
                </h3>
                <p className="text-sm">I got your message bro</p>
                <p className="text-sm text-muted"><i className="far fa-clock mr-1"></i> 4 Hours Ago</p>
              </div>
            </div>
          </a>
          <div className="dropdown-divider"></div>
          <a href="#" className="dropdown-item">
            <div className="media">
              <img src="../../dist/img/user3-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" />
              <div className="media-body">
                <h3 className="dropdown-item-title">
                  Nora Silvester
                  <span className="float-right text-sm text-warning"><i className="fas fa-star"></i></span>
                </h3>
                <p className="text-sm">The subject goes here</p>
                <p className="text-sm text-muted"><i className="far fa-clock mr-1"></i> 4 Hours Ago</p>
              </div>
            </div>
          </a>
          <div className="dropdown-divider"></div>
          <a href="#" className="dropdown-item dropdown-footer">See All Messages</a>
        </div> */}
      </li>
      <li className="nav-item dropdown">
        <a className="nav-link" data-toggle="dropdown" href="#">
          <i className="fas fa-th-large"></i>
        </a>
        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <div className="dropdown-divider"></div>
          {/* <a href="#" className="dropdown-item">
            <div className="d-inline-block w-100 text-center">
            <i className="fas fa-user-circle" style={{fontSize: '2em'}}></i>
              <span className="d-block">Alexander Pierce</span>
              <span style={{ fontSize: '12px', top: '-8px', position: 'relative' }}>useremail@gmail.com</span>
            </div>
          </a> */}
          <a href="#" className="dropdown-item">
            <i className="fas fa-cogs mr-2"></i> Settings
          </a>
          <div className="dropdown-divider"></div>
          <a href="#" className="dropdown-item">
            <i className="fas fa-user-circle mr-2"></i> Profile
          </a>
          <div className="dropdown-divider"></div>
          <a href="#" className="dropdown-item">
            <i className="fas fa-sign-out-alt mr-2"></i> Sign Out
          </a>
        </div>
      </li>
    </ul>
  </nav>
)