import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { useTable, useSortBy } from 'react-table';
import { Button, Input, Row, Col } from 'reactstrap';

const DataTable = ({ columns, data, loading, page, nextPage, onSearch, canNextPage, canPrevPage, previousPage, error }) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  }, useSortBy);

  const [searchValue, setSearchValue] = useState('');

  const filterData = (text) => {
    setSearchValue(text);
    onSearch(text);
  };

  const loadTable = () => {
    if (error) return <tr><td className="text-center" colSpan="99">Failed Loading Data</td></tr>
    if (loading) return <tr><td className="text-center" colSpan="99">Loading...</td></tr>;
    return rows.map(
      (row, i) => {
        prepareRow(row);
        return (
          <tr {...row.getRowProps()}>
            {row.cells.map(cell => {
              return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
            })}
          </tr>
        );
      }
    );
  }


  // Render the UI for your table
  return (
    <>
      <div>
        <Row>
          <Col className="d-flex">
            <h6 className="my-auto">Page <b>{page}</b></h6>
          </Col>
          <Col>
            <Input disabled={loading || error} onChange={({ target: { value } }) => filterData(value)} value={searchValue} placeholder="Search" />
          </Col>
        </Row>
      </div>
      <table className="display dataTable no-footer" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {loadTable()}
        </tbody>
      </table>
      <div className="pagination">
        <Button disabled={loading || canPrevPage || error} onClick={previousPage} className="m-2" type="primary">{'<< Previous'}</Button>
        <Button disabled={loading || canNextPage || error} onClick={nextPage} className="m-2" type="primary">{'Next >>'}</Button>
        <h6 className="m-2 my-3">Go To Page</h6>

        <Input disabled={loading || error} style={{ width: '130px' }} placeholder="Page #" className="m-2" />
      </div>
    </>
  )
}

DataTable.protoTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
};

DataTable.defaultProps = {
  columns: [],
  data: [],
};

export default DataTable;
