export const passengersApiBaseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_ENDPOINT}`;
export const divisionsApiBaseUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_DIVISION_API_ENDPOINT}`;

export const query = {
  getStates: '/states',
  googleAuthUrl: '/auth/google/authUrl',
};

export const mutation = {
  verifyClient: '/passengers/verify',
};

export default {
  divisionsApiBaseUrl,
  passengersApiBaseUrl,
  query,
  mutation,
};
